import { Component, OnInit } from '@angular/core';
import { ShareddataService } from "../../services/shareddata.service";
import { NavigationEnd, Router } from "@angular/router";

@Component({
  selector: 'div[app-video]',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
  host: {
    class: "bg-gray-100"
  }
})
export class VideoComponent implements OnInit {

  data: any;
  video: any;

  constructor(
    private shareddata: ShareddataService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.shareddata.video.subscribe(state => this.data = state);

    let that = this;
    _wq.push({
      id: this.data.id,
      onReady: function(video) {
        that.video = video;

        that.video.bind("end", function() {
          that.video.popover.hide()
        });

      }
    });

    // Closes video popover on route changes
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      that.video.popover.hide()
    });
  }

}
