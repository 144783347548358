import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ShareddataService } from "./shareddata.service";

@Injectable(
  {
    providedIn: 'root',
  }
)
export class ContactService { 

  options: any;
  token: string;

  constructor(
    private http: HttpClient,
    private shareddata: ShareddataService,
    ) {
    this.options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
    };
  }

  isProd() {
    return true;
    // (
    //   ((window.location.hostname.indexOf("www.edsandosa.com") > -1) || (window.location.hostname.indexOf("edsandosa.com") > -1)) && 
    //   !((window.location.hostname.indexOf("dev") > -1) || (window.location.hostname.indexOf("staging") > -1))
    // );
  }

  getEndPoint() {
    let end: string = "https://test-jazz-cleartrak.solutions.iqvia.com";
    if (this.isProd()) {
      end = "https://jazz-cleartrak.solutions.iqvia.com";
    }
    return end;
  }

  getToken() {
    let loginData = "grant_type=password&username=Jazz.h4b&password=JazzHavas2019!%23";
    if (this.isProd()) {
      loginData = "grant_type=password&username=edsandosaapi&password=h3kw%21IP1";
    }

    return this.http.post(this.getEndPoint() + "/Token", loginData, this.options);
  }

  subscribeUser(fname, lname, email, specialty, zipcode) {

    this.shareddata.token.subscribe(state => this.token = state);

    // console.log('subscribeUser', fname, lname, email, specialty, zipcode, this.token);

    let autorization = {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.token)
    };

    let data = {};
    if (this.isProd()) {
      data = {
        "FirstName": fname,
        "LastName": lname,
        "PrimarySpecialtyId": specialty,
        "DataSource": "esandosa.com",
        "NPI": "123456789",
        "HCPLocations": [
            {
                "EmailAddress": email,
                "DataSource": "esandosa.com",
                "ZipCode": zipcode
            }
        ],
        "HCPAttributes": [
            {
                "Code": "MCBU",
                "Type": "HCP",
                "FieldName": "110004579",
                "FieldValue": "OCEMarketing",
                "DataSource": "esandosa.com"
            },
            {
                "Code": "API",
                "Type": "Contact Registration",
                "FieldName": "Vendor",
                "FieldValue": "esandosa",
                "DataSource": "esandosa.com"
            },
            {
                "Code": "API",
                "Type": "Contact Registration",
                "FieldName": "edsandosa URL",
                "FieldValue": "edsandosa.com",
                "DataSource": "esandosa.com"
            }
        ],
        "Opt": "[{\"OptId\":\"8C1FEEDA-49E6-4D8B-A12A-019ADFA38FBA\",\"ColumnName\":\"BrandId\",\"OptOut\":\"false\",\"DataSource\":\"esandosa.com\"},{\"OptId\":\"88C30146-7AFC-4887-9B7C-F33B1A96027D\",\"ColumnName\":\"BrandId\",\"OptOut\":\"false\",\"DataSource\":\"esandosa.com\"},{\"OptId\":\"7426963A-A813-466C-A317-42EBD03D3AAD\",\"ColumnName\":\"BrandId\",\"OptOut\":\"false\",\"DataSource\":\"esandosa.com\"}]"
      };
    } else {
      data = {
        "FirstName": fname,
        "LastName": lname,
        "PrimarySpecialtyId": specialty,
        "DataSource": "esandosa.com",
        "NPI": "123456789",
        "HCPLocations": [
            {
                "EmailAddress": email,
                "DataSource": "esandosa.com",
                "ZipCode": zipcode
            }
        ],
        "HCPAttributes": [
            {
                "Code": "MCBU",
                "Type": "HCP",
                "FieldName": "110004579",
                "FieldValue": "OCEMarketing",
                "DataSource": "esandosa.com"
            },
            {
                "Code": "API",
                "Type": "Contact Registration",
                "FieldName": "Vendor",
                "FieldValue": "esandosa",
                "DataSource": "esandosa.com"
            },
            {
                "Code": "API",
                "Type": "Contact Registration",
                "FieldName": "edsandosa URL",
                "FieldValue": "edsandosa.com",
                "DataSource": "esandosa.com"
            }
        ],
        "Opt": "[{\"OptId\":\"EF25ABCB-BA99-4DD0-B826-83B22643E120\",\"ColumnName\":\"BrandId\",\"OptOut\":\"false\",\"DataSource\":\"esandosa.com\"},{\"OptId\":\"6E33A42C-B8DA-45A6-9570-0CC40E866F86\",\"ColumnName\":\"BrandId\",\"OptOut\":\"false\",\"DataSource\":\"esandosa.com\"},{\"OptId\":\"7560F7CB-210D-45A4-89D5-79D72FBB822B\",\"ColumnName\":\"BrandId\",\"OptOut\":\"false\",\"DataSource\":\"esandosa.com\"}]"
      };
    }

    return this.http.post(this.getEndPoint() + "/api/HCPRegistration", data, autorization);
  }

  unsubscribeUser(email) {

    this.shareddata.token.subscribe(state => this.token = state);

    // console.log('unsubscribeUser', email);

    let autorization = {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.token)
    };

    let data = {};
    if (this.isProd()) {
      data = {
        "HCPLocations": [
            {
                "EmailAddress": email,
                "DataSource": "esandosa.com"
            }
        ],
        "HCPAttributes": [
            {
                "Code": "MCBU",
                "Type": "HCP",
                "FieldName": "110004579",
                "FieldValue": "OCEMarketing",
                "DataSource": "esandosa.com"
            },
            {
                "Code": "API",
                "Type": "Contact Registration",
                "FieldName": "Vendor",
                "FieldValue": "esandosa",
                "DataSource": "esandosa.com"
            },
            {
                "Code": "API",
                "Type": "Contact Registration",
                "FieldName": "Registration Form",
                "FieldValue": "Unsubscribe",
                "DataSource": "esandosa.com"
            },
            {
                "Code": "API",
                "Type": "Contact Registration",
                "FieldName": "edsandosa URL",
                "FieldValue": "edsandosa.com",
                "DataSource": "esandosa.com"
            }
        ],
        "Opt": "[{\"OptId\":\"88C30146-7AFC-4887-9B7C-F33B1A96027D\",\"ColumnName\":\"BrandId\",\"OptOut\":\"true\",\"DataSource\":\"esandosa.com\"}]"
      };
    } else {
      data = {
        "HCPLocations": [
            {
                "EmailAddress": email,
                "DataSource": "esandosa.com"
            }
        ],
        "HCPAttributes": [
            {
                "Code": "MCBU",
                "Type": "HCP",
                "FieldName": "110004579",
                "FieldValue": "OCEMarketing",
                "DataSource": "esandosa.com"
            },
            {
                "Code": "API",
                "Type": "Contact Registration",
                "FieldName": "Vendor",
                "FieldValue": "esandosa",
                "DataSource": "esandosa.com"
            },
            {
                "Code": "API",
                "Type": "Contact Registration",
                "FieldName": "Registration Form",
                "FieldValue": "Unsubscribe",
                "DataSource": "esandosa.com"
            },
            {
                "Code": "API",
                "Type": "Contact Registration",
                "FieldName": "edsandosa URL",
                "FieldValue": "edsandosa.com",
                "DataSource": "esandosa.com"
            }
        ],
        "Opt": "[{\"OptId\":\"6E33A42C-B8DA-45A6-9570-0CC40E866F86\",\"ColumnName\":\"BrandId\",\"OptOut\":\"true\",\"DataSource\":\"esandosa.com\"}]"
      };
    }
    

    return this.http.post(this.getEndPoint() + "/api/HCPRegistration", data, autorization);
  }

}