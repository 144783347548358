import { Component, OnInit, Input, HostListener, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss']
})

export class PopoverComponent implements OnInit {

  @Input() data: any;
  @Input() placement: string = "bottom-left";
  @Input() invertMobile: boolean = false
  @Input() orientationChange: any;
  isOpen: boolean;
  hover: boolean;
  isMobilevar: boolean;
  isTabletvar: boolean;
  @ViewChild('sign') sign:ElementRef;

  constructor(
    private deviceService: DeviceDetectorService,
    private renderer:Renderer2
  ) {
    this.isOpen = false;
    this.hover = false;
    this.isMobile();
    this.isTablet();
  }

  ngOnInit(): void {
    if ((this.isMobilevar || this.isTabletvar) && this.data.invertMobile) {
      this.placement = (this.placement == "bottom-left" ? "bottom-right": "bottom-left");
    }
  }

  close() {
    console.log('close')
  }
  toggleSign() {
    this.isOpen = !this.isOpen;
  }

  public isMobile() {
    this.isMobilevar = this.deviceService.isMobile();
  }
  public isTablet() {
    this.isTabletvar = this.deviceService.isTablet();
  }

  mouseEnter() {
    this.hover = true;
  }
  mouseLeave() {
    this.hover = false;
  }

  @HostListener('window:orientationchange', [])
  onOrientationChange() {
    if (this.sign.nativeElement.classList.contains('opened')) {
      this.sign.nativeElement.click();
      this.hover = false;
      this.isOpen = false;
    }
  }
}
