import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 100],
};

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import("./dashboard/dashboard.module").then(m => m.DashboardModule)
  },


  {
    path: 'the-eds-and-osa-connection',
    redirectTo: "the-eds-and-osa-connection/hypoxia-sleep-fragmentation"
  },
    {
      path: 'the-eds-and-osa-connection/hypoxia-sleep-fragmentation',
      loadChildren: () => import("./pages/fragmentation/fragmentation.module").then(m => m.FragmentationModule)
    },
    {
      path: 'the-eds-and-osa-connection/brain-alterations',
      loadChildren: () => import("./pages/brain/brain.module").then(m => m.BrainModule)
    },


    {
      path: 'prevalence-of-eds-in-osa',
      loadChildren: () => import("./pages/prevalence/prevalence.module").then(m => m.PrevalenceModule)
    },


    {
      path: 'real-impact-of-eds-in-osa',
      redirectTo: "real-impact-of-eds-in-osa/eds-osa-patients"
    },
      {
        path: 'real-impact-of-eds-in-osa/eds-osa-patients',
        loadChildren: () => import("./pages/patients/patients.module").then(m => m.PatientsModule)
      },
      {
        path: 'real-impact-of-eds-in-osa/public',
        loadChildren: () => import("./pages/public/public.module").then(m => m.PublicModule)
      },


    {
      path: 'screening-and-monitoring',
      redirectTo: "screening-and-monitoring/importance-of-eds-screening"
    },
      {
        path: 'screening-and-monitoring/importance-of-eds-screening',
        loadChildren: () => import("./pages/importance/importance.module").then(m => m.ImportanceModule)
      },
      {
        path: 'screening-and-monitoring/discussing-excessive-daytime-sleepiness',
        loadChildren: () => import("./pages/discussing/discussing.module").then(m => m.DiscussingModule)
      },


    {
      path: 'treating-eds-in-osa',
      loadChildren: () => import("./pages/treating/treating.module").then(m => m.TreatingModule)
    },


    {
      path: 'resources',
      redirectTo: '/404'//"resources/tools"
    },
      {
        path: 'resources/tools',
        redirectTo: '/404'//loadChildren: () => import("./pages/tools/tools.module").then(m => m.ToolsModule),
      },
      {
        path: 'resources/video',
        redirectTo: '/404'//loadChildren: () => import("./pages/video/video.module").then(m => m.VideoModule),
      },
      {
        path: 'resources/science',
        redirectTo: '/404'//loadChildren: () => import("./pages/science/science.module").then(m => m.ScienceModule),
      },

    {
      path: 'sitemap',
      loadChildren: () => import("./pages/sitemap/sitemap.module").then(m => m.SitemapModule)
    },

    {
      path: 'unsubscribe',
      loadChildren: () => import("./pages/unsubscribe/unsubscribe.module").then(m => m.UnsubscribeModule)
    },


  {
    path: '404',
    loadChildren: () => import("./pages/notfound/notfound.module").then(m => m.NotfoundModule)
  },
  {
    path: "**",
    redirectTo: "/404"
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
