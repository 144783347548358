import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'div[app-list]',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  @Input() list: any;
  @Input() listclass: string;

  constructor() { }

  ngOnInit(): void {
  }

}
