<div class="container-fluid footer-component pt-4 pb-3 py-lg-4">
  <div class="row">
    <div class="container-lg">
      <div class="row">
        <div class="col-12 col-lg-7">
          <a href="{{parentSite.link}}" target="_blank" rel="nofollow">
            <picture>
              <source srcset="{{parentSite.icon}}.{{parentSite.iconExt}} 1x, {{parentSite.icon}}@2x.{{parentSite.iconExt}} 2x">
              <img src="{{parentSite.icon}}.{{parentSite.iconExt}}" alt="{{parentSite.alt}}" class="img-fluid"/>
            </picture>
          </a>
        </div>
        <div class="col-12 col-lg-5 text-lg-right">
          <nav>
            <ul class="m-0 py-3 py-lg-0">
              <li *ngFor="let link of footerLinks; let first = first" [ngClass]="{'first': first}">
                <a *ngIf=link.external target="_blank" href="{{link.link}}" rel="nofollow" [innerHTML]=link.text class="text-underline"></a>
                <a *ngIf=!link.external [routerLink]=link.link [innerHTML]=link.text class="text-underline"></a>
              </li>
            </ul>
          </nav>
          <p [innerHTML]=globalContents.intented class="pt-lg-3 pl-lg-5 d-lg-none" hidden></p>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="container-lg">
      <div class="row">
        <div class="col-12 col-lg-8">
          <p [innerHTML]="globalContents.copyright + globalContents.intented" class="pt-lg-3 mb-lg-2"></p>
          <p [innerHTML]=globalContents.lastupdate class="mb-0"></p>
        </div>
        <div class="d-none d-lg-block col-5" hidden>
           <p [innerHTML]=globalContents.intented class="pt-lg-3 text-right pr-lg-4" hidden></p> 
        </div>
      </div>
    </div>
  </div>
</div>
