import { Component, OnInit } from '@angular/core';
import * as data from "../../../../assets/data/links.json";
import * as globaldata from "../../../../assets/data/all.json";

@Component({
  selector: 'div[app-footer]',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  parentSite: any;
  footerLinks: any;
  globalContents: any;

  constructor() { }

  ngOnInit(): void {
    this.parentSite = data[0].parentLink[0];
    this.footerLinks = data[0].footerLinks;
    this.globalContents = globaldata[0].global;
  }

}
