import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { SharedComponentsModule } from './shared/components/shared-components.module';
import { NavigationComponent } from './shared/components/navigation/navigation.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { ReferencesComponent } from './shared/components/references/references.component';
import { RibbonComponent } from './shared/components/ribbon/ribbon.component';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { UpdatesFormComponent } from './shared/components/updates-form/updates-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { AngularResizedEventModule } from 'angular-resize-event';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    ReferencesComponent,
    RibbonComponent,
    FooterComponent,
    UpdatesFormComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    DeviceDetectorModule.forRoot(),
    // AngularResizedEventModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
