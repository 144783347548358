import { Component, OnInit } from '@angular/core';
import { ShareddataService } from "../../services/shareddata.service";

@Component({
  selector: 'div[app-extrasection]',
  templateUrl: './extrasection.component.html',
  styleUrls: ['./extrasection.component.scss'],
  host: {
    class: "container-lg"
  }
})
export class ExtrasectionComponent implements OnInit {

  sectiondata: any;

  constructor(
    private shareddata: ShareddataService
  ) {
    this.sectiondata = <any>({});
  }

  ngOnInit(): void {
    this.shareddata.extraSection.subscribe(state => this.sectiondata = state);
  }

}
