import { Component, OnInit, Input } from '@angular/core';
import { ShareddataService } from "../../services/shareddata.service";

@Component({
  selector: 'div[app-references]',
  templateUrl: './references.component.html',
  styleUrls: ['./references.component.scss']
})
export class ReferencesComponent implements OnInit {

  data: any;
  refsBarOpen: boolean;

  constructor(
    private shareddata: ShareddataService,
  ) {}

  ngOnInit(): void {
    this.shareddata.references.subscribe(state => this.data = state)
    this.shareddata.refsBar.subscribe(state => this.refsBarOpen = state)
  }

  toggleRefsBar() {
    this.shareddata.toggleReferences(!this.refsBarOpen);
  }

}
