import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";
import { VideoComponent } from './video/video.component';
import { PopoverComponent } from './popover/popover.component';
import { NgbModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { ListComponent } from './list/list.component';
import { FootnotesComponent } from './footnotes/footnotes.component';
import { ExtrasectionComponent } from './extrasection/extrasection.component';

@NgModule({
  declarations: [
    VideoComponent,
    PopoverComponent,
    ListComponent,
    FootnotesComponent,
    ExtrasectionComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    NgbPopoverModule
  ],
  exports: [
    CommonModule,
    RouterModule,
    NgbModule,
    NgbPopoverModule,
    VideoComponent,
    PopoverComponent,
    ListComponent,
    FootnotesComponent,
    ExtrasectionComponent,
  ]
})
export class SharedComponentsModule { }