<form id="contact-form" class="container-lg pt-2 pb-3" [formGroup]="form" (ngSubmit)="onSubmit()" validate-non-visible-controls [ngClass]="{'submitted': localFormSubmitted}">
  <div class="container-lg">

    <div class="row d-lg-none pb-2 mt-1">
      <div class="d-flex w-100 justify-content-between align-items-center">
        <label for="" [innerHTML]="formContents.required" [ngClass]="{'ng-touched ng-invalid': ((!form.touched && errors > 0) || ((form.touched || form.dirty) && !form.valid))}"></label>
        <div (click)="closeUpdate()" class="icon icon--close icon--close-yellow"></div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-12 col-lg-9 pl-lg-0">
        <input class="form-control" type="email" name="email" id="email" formControlName="email" placeholder="{{formContents.email}}">
      </div>
      <label class="d-none d-lg-block col-lg-3 align-self-top text-right required pr-0" for="" [innerHTML]="formContents.required" [ngClass]="{'ng-touched ng-invalid': ((!form.touched && errors > 0) || ((form.touched || form.dirty) && !form.valid))}"></label>
    </div>

    <div class="form-group row">
      <div class="col-6 pl-lg-0">
        <input class="form-control" type="text" name="firstname" id="firstname" formControlName="firstname" placeholder="{{formContents.firstname}}">
      </div>

      <div class="col-6">
        <input class="form-control" type="text" name="lastname" id="lastname" formControlName="lastname" placeholder="{{formContents.lastname}}">
      </div>
    </div>
    
    <div class="form-group row">
      <div class="col-6 pl-lg-0">
        <input type="hidden" name="specialty" id="specialty" #specialt [ngClass]="{'ng-touched': wasDropdownTouched && !isDropdownOpen && specialt.value != formContents.specialty }" formControlName="specialty" class="{{(specialt.value == formContents.specialty).toString()}}">
        <div class="dropdown" dropdown [ngClass]="{'open': isDropdownOpen}">
          <button id="spacialty2" tabindex="0" dropdownToggle class="dropdown-toggle form-control" type="button" (click)="toggleSpecialty()">
            <span class="copy">{{activeItem}}</span><span class="arrow desktop icon icon--arrow-down"></span><span class="arrow icon icon--arrow-down-red"></span>
          </button>
          <ul class="dropdown-menu no-branded shadow w-100" role="menu">
            <li *ngFor="let specialty of formContents.specialties" class="menuitem" role="menuitem">
              <a class="dropdown-item" [attr.data-value]=specialty.id [attr.data-name]=specialty.specialty tabindex="0" (click)=pickSpecialty($event) [innerHTML]=specialty.specialty></a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-6 d-flex align-items-end">
        <input class="form-control" type="text" name="zip" id="zip" formControlName="zip" placeholder="{{formContents.zip}}" pattern="^[0-9]{5}(?:-[0-9]{4})?$">
      </div>
    </div>

    <div class="row">
      <div class="col-11 col-lg-10">
        <div class="row mb-3 mb-lg-1 mt-2">
          <div class="custom-control custom-checkbox">
            <input class="custom-control-input" type="checkbox" id="age{{id}}" name="age" formControlName="age" required />
            <label for="age{{id}}" class="custom-control-label" [innerHTML]=formContents.age></label>
          </div>
        </div>
        <div class="row">
          <div class="custom-control custom-checkbox">
            <input class="custom-control-input" type="checkbox" id="professional{{id}}" name="professional" formControlName="professional" required/>
            <label for="professional{{id}}" class="custom-control-label" [innerHTML]=formContents.professional></label>
          </div>
        </div>
      </div>
      <div class="col-11 col-lg-2 d-lg-flex text-right">
        <div (click)="onSubmit()" class="hotspot submit align-self-center mt-2 mt-lg-5 ml-lg-3">
          <span class=" icon icon--next icon-rounded" [innerHTML]="formContents.submit"></span>
        </div>
      </div>
    </div>
  </div>
  <div id="thankyou" class="container d-flex align-items-center justify-content-center">
    <p [innerHTML]=formContents.thankyou class="mb-5 mb-lg-0"></p>
  </div>
</form>