<div *ngIf="data.ctas?.length > 0" class="container-fluid ribbon-component {{data.bgClass}}" [style.background-image]="data.deskBg ? 'url(/assets/img/' + data.deskBg + ')' : 'none'">
  <div class="row row p-0 m-0">
    <div class="container">
      <div class="row flex-column flex-lg-row">
        <div *ngFor="let cta of data.ctas; let last = last" class="col d-lg-flex justify-content-center" [ngClass]="{'mb-3 mb-lg-0': !last}">
          <ng-container *ngIf="cta.hide">
            <a *ngIf="cta.type == 'big-icon'; else defaultBlock" href="#" class="d-flex align-items-center justify-content-center mx-4 mx-lg-0" [ngClass]="{'no-hotspot':updateSubmitted}" (click)="openForm($event)">
              <picture>
                <source srcset="/assets/img/{{cta.icon}}.png 1x, /assets/img/{{cta.icon}}@2x.png 2x">
                <img src="/assets/img/{{cta.icon}}.png" alt="{{cta.alt}}" />
              </picture>
              <span class="ml-3 ml-lg-4">
                <strong *ngIf=!updateSubmitted [innerHTML]=cta.heading class="calibri calibri-bold"></strong>
                <strong *ngIf=updateSubmitted [innerHTML]=cta.altheading class="calibri calibri-bold"></strong>
              </span>
            </a>
            <ng-template #defaultBlock>
              <a [routerLink]="cta.link" class="{{cta.class}} w-100 d-flex justify-content-between">
                <div class="d-flex flex-column justify-content-center">
                  <div [innerHTML]=cta.heading class="heading-text text-left"></div>
                  <div [innerHTML]=cta.altheading class="heading-text text-left"></div>
                  <span class="link mt-1" [innerHTML]=cta.linktext></span>
                </div>
                <div class="icon icon--next icon-rounded align-self-center"></div>
              </a>
            </ng-template>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <img *ngIf="data.deskBg" class="d-none" src="/assets/img/{{ data.deskBg }}" alt="{{ data.deskBgAlt }}" />
</div>
