import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
   providedIn: 'root'
})
export class CanonicalService {

  private link: HTMLLinkElement;
  private resourcelink: HTMLLinkElement;

  constructor(@Inject(DOCUMENT) private doc) { }

  createLinkForCanonicalURL() {
    let d = this.doc.querySelector('link[rel=\"canonical\"][href$=\".pdf\"]');
    d && d.remove();
    if (this.link === undefined) {
      this.link = this.doc.createElement('link');
      this.link.setAttribute('rel', 'canonical');
      this.doc.head.appendChild(this.link);
    }
    this.link.setAttribute('href', this.doc.URL.split('?')[0]);
  }

  createLinkForCanonicalResource(resource: string) {
    this.resourcelink = this.doc.createElement('link');
    this.resourcelink.setAttribute('rel', 'canonical');
    this.doc.head.appendChild(this.resourcelink);
    this.resourcelink.setAttribute('href', resource);
  }
}