<section *ngIf="(sectiondata | json) != '{}'">

  <div class="container-lg px-lg-0">
    <h2 [innerHTML]=sectiondata.h2 class="{{sectiondata.headingclass}}"></h2>

    <div class="mobile-wrapper">
      <div class="row my-4 my-lg-5">
        <div class="col-12 col-lg-4" *ngFor="let column of sectiondata.columns; let last = last" [ngClass]="{'mb-3 mb-lg-0': !last}">
          <app-popover *ngIf=column.placement [placement]=column.placement [data]=column></app-popover>
          <app-popover *ngIf=!column.placement [data]=column></app-popover>
        </div>
      </div>
    </div>

    <p [innerHTML]=sectiondata.paragraph class="mb-0"></p>
  </div>

</section>