<span class="pop">
  <span [innerHTML]=data.preCopy></span>
  <span *ngIf=data.siblingCopy
    class="pop hotspot"
    [ngClass]="{'hover': hover || isOpen}"
    (mouseenter) ="mouseEnter()"
    (mouseleave) ="mouseLeave()"
    (click)="popover.toggle();">
    <b [innerHTML]=data.siblingCopy></b>
  </span>
  <span class="text-nowrap" [ngClass]="{'d-flex flex-row-reverse justify-content-end': data.part1Copy}">
    <ng-template #popContent><span [innerHTML]=data.popCopy></span></ng-template>
    <button
      type="button"
      class="btn pop-btn p-0 {{data.class}} {{placement}}"
      [autoClose]="true"
      [ngbPopover]=popContent
      placement="{{placement}}"
      #popover="ngbPopover"
      (shown)="toggleSign()"
      (hidden)="toggleSign()"
      popoverClass="custom-popover-class"
      [ngClass]="{'open': isOpen, 'multiparts': data.part1Copy, 'hover': hover}"
      (mouseenter) ="mouseEnter()"
      (mouseleave) ="mouseLeave()">
      <b *ngIf=data.linkCopy [innerHTML]="data.linkCopy"></b>
      <b class="d-inline-block" *ngIf=data.part1Copy [innerHTML]="data.part1Copy"></b>
      <br *ngIf=data.part1Copy />
      <b class="d-inline-block" *ngIf=data.part2Copy [innerHTML]="data.part2Copy"></b>
    </button>
    <button type="button" #sign class="btn pop-sign p-0" (click)="popover.toggle();" [ngClass]="{'opened': isOpen, 'multiparts': data.part1Copy}"><b *ngIf=isOpen>-</b><b *ngIf=!isOpen>+</b></button>
  </span>
  <span [innerHTML]=data.postCopy></span>
</span>