import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import {filter, map, mergeMap} from 'rxjs/operators';
import { CanonicalService } from './shared/services/canonical.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'EDS&OSA';
  constructor(
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private activatedRoute: ActivatedRoute,
    private canonicalService: CanonicalService,
    ) {
  }

  ngOnInit(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }))
      .pipe(filter((route) => route.outlet === 'primary'))
      .pipe(mergeMap((route) => route.data))
      .subscribe(
        (event) => {
          event['title'] && this.titleService.setTitle(event['title']);

          //META TAGS
          event['description'] && this.meta.updateTag({name: 'description', content: event['description'] });

          //OG TAGS
          event['ogtitle'] && this.meta.updateTag({name: 'og:title', content: event['ogtitle'] });
          event['ogdescription'] && this.meta.updateTag({name: 'og:description', content: event['ogdescription'] });
          event['twtitle'] && this.meta.updateTag({name: 'twitter:title', content: event['twtitle'] || event['og:title'] });
          event['twdescription'] && this.meta.updateTag({name: 'twitter:description', content: event['twdescription'] });

          // Canonical
          this.canonicalService.createLinkForCanonicalURL();

          // Canonical PDFs
          event['canonicalresource'] && this.canonicalService.createLinkForCanonicalResource(event['canonicalresource'])

        });

  }

}
