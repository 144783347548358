import { Component, OnInit } from '@angular/core';
import { Router, NavigationExtras } from "@angular/router";
import * as data from "../../../../assets/data/links.json";
import * as globaldata from "../../../../assets/data/all.json";
import { ShareddataService } from "../../services/shareddata.service";
import { DeviceDetectorService } from 'ngx-device-detector';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'header[app-navigation]',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  host: { 
    class: "fixed-top w-100",
    style: "background-color: rgba(96,96,98,.95);"
  }
})

export class NavigationComponent implements OnInit {

  headerLinks: any;
  globalContents: any;
  subMenuOpen: boolean;
  navbarOpen: boolean;
  navOpenedId: string;
  navOpenedText: string;

  updateOpen: boolean;
  updateSubmitted: boolean;
  isMobilevar: boolean;
  isTabletvar: boolean;
  showRegister:boolean;
  constructor(
    private router: Router,
    private shareddata: ShareddataService,
    private deviceService: DeviceDetectorService,
    private _cookieService: CookieService
  ) {
    this.isMobile();
    this.isTablet();
  }

  ngOnInit() {
    this.subMenuOpen = true;
    this.navbarOpen = false;

    this.headerLinks = data[0].headerLinks;
    this.globalContents = globaldata[0].global;

    this.shareddata.navBar.subscribe(state => this.navbarOpen = state)

    //Form shared vars
    this.shareddata.formOpened.subscribe(state => this.updateOpen = state)
    this.shareddata.submit.subscribe(state => this.updateSubmitted = state)
    
    this.updateSubmitted = this._cookieService.check('formsubmitted');
    this.showRegister=false;
    this.isMobile();
    this.isTablet();
  }

  pickHome() {
    this.shareddata.openUpdateForm(false);
    this.shareddata.toggleNav(false);
    this.shareddata.toggleReferences(false);
  }

  pickNav(e) {
    this.clearOpened();
    this.shareddata.toggleNav(false);
    this.shareddata.openUpdateForm(false);
    this.shareddata.toggleReferences(false);
  }
  clearOpened() {
    let openedSubNavs = document.getElementsByClassName("opened");
    for (let i = 0; i < openedSubNavs.length; i++) {
      openedSubNavs[i].classList.remove('opened');
    }
  }
  pickParent(e) {
    this.clearOpened();
    if (this.isMobilevar || this.isTabletvar) {
      e.target.classList.add("opened");
    }
    e.stopPropagation();
  }

  pickSubNavDesktop(e) {
    this.shareddata.openUpdateForm(false);
    this.shareddata.toggleReferences(false);
    this.shareddata.toggleNav(false);
    //this timer helps to hide subnavs on desktop
    this.subMenuOpen = false;
    setTimeout(() => {
      this.subMenuOpen = true;
    }, 500);
    e.stopPropagation();
  }

  pickNavMobile(e) {
    e.target.classList.add('hover');
    setTimeout(() => {
      e.target.classList.remove('hover');
      document.getElementsByClassName("opened")[0] && document.getElementsByClassName("opened")[0].classList.remove('opened');
      this.shareddata.toggleNav(false);
      this.router.navigate([e.target.dataset.route]);
    }, 1000);
    e.stopPropagation();
  }

  pickSubNavMobile(e) {
    this.shareddata.openUpdateForm(false);
    this.shareddata.toggleReferences(false);
    e.target.classList.add('hover');
    // setTimeout(() => {
    //   e.target.classList.remove('hover');
    //   document.getElementsByClassName("opened")[0] && document.getElementsByClassName("opened")[0].classList.remove('opened');
    //   this.shareddata.toggleNav(false);

    //   let navigationExtras: NavigationExtras = {
    //     fragment: e.target.dataset.fragment
    //   };
    //   this.router.navigate([e.target.dataset.route], navigationExtras);
    // }, 1000);

    setTimeout(() => {
      e.target.classList.remove('hover');
      document.getElementsByClassName("opened")[0] && document.getElementsByClassName("opened")[0].classList.remove('opened');
      this.shareddata.toggleNav(false);

      this.router.navigate([e.target.dataset.route]);
    }, 1000);
    e.stopPropagation();
  }

  toggleNav() {
    if (this.isMobilevar || this.isTabletvar) {
      let active = document.getElementsByClassName("active");
      let openedSubNavs = document.getElementsByClassName("opened");
      for (let i = 0; i < openedSubNavs.length; i++) {
        openedSubNavs[i].classList.remove('opened');
      }
      active.length > 0 && active[0].classList.add('opened');
    }
    this.updateOpen && this.shareddata.openUpdateForm(false);
    this.shareddata.toggleNav(!this.navbarOpen);
  }

  toggleUpdate() {
    this.navbarOpen && this.shareddata.toggleNav(false);
    this.shareddata.openUpdateForm(!this.updateOpen);
    return false;
  }

  toggleUpdateDesktop() {
    this.shareddata.openUpdateForm(true);
  }
  toggleUpdateDesktopClose() {
    // this.updateOpen = false;
    this.shareddata.openUpdateForm(false);
    return false;
  }

  mouseEnter(e) {
    e.target.parentElement.classList.add('hover');
  }
  mouseLeave(e) {
    e.target.parentElement.classList.remove('hover');
  }

  public isMobile() {
    this.isMobilevar = this.deviceService.isMobile();
  }
  public isTablet() {
    this.isTabletvar = this.deviceService.isTablet();
  }
}
