<div class="intentded-bar">
  <div class="container-lg">
    <p class="pt-2 pb-1 m-0" [innerHTML]=globalContents.hcp></p>
  </div>
</div>

<div class="container-lg">
  <nav class="navbar navbar-dark navbar-expand-lg p-lg-0" [ngClass]="{'open': navbarOpen}">

    <a class="navbar-brand" [routerLink]="['/']" (click)="pickHome()">
      <img src="/assets/img/{{globalContents.homelogo}}" alt="{{globalContents.althomelogo}}" />
    </a>
    <div>
      <span *ngIf="!updateSubmitted && showRegister" class="update navbar-toggler dropdown-toggle" data-toggle="collapse" data-target="#navbarUpdateDropdown" (click)="toggleUpdate()">
        <img src="/assets/img/{{globalContents.update}}" alt="{{globalContents.altupdate}}" />
      </span>
      <button type="button" (click)="toggleNav()" class="navbar-toggler dropdown-toggle hamburger hamburger--squeeze d-lg-none" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation" [ngClass]="{'is-active': navbarOpen}">
        <span class="hamburger-box no-actionable">
          <span class="hamburger-inner"></span>
        </span>
      </button>
    </div>

    <div class="collapse navbar-collapse d-lg-none" id="navbarNavDropdown" [ngClass]="{ 'show': navbarOpen }">
      <ul *ngFor="let link of headerLinks" class="navbar-nav d-flex justify-content-between w-100">
          <li *ngIf="link.show" class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" (click)=pickParent($event) [ngClass]="{'has-subnav': link.submenu}">
            <a *ngIf="!link.submenu" [routerLink]="link.link" [innerHTML]=link.text class="d-none d-lg-block nav-link d-lg-flex align-items-end" (click)="pickNav($event)" (mouseenter) ="mouseEnter($event)" (mouseleave) ="mouseLeave($event)"></a>
            <a *ngIf="link.submenu" [routerLink]="link.link" [innerHTML]=link.text class="d-none d-lg-block nav-link d-lg-flex align-items-end" (click)="pickNav($event)" (mouseenter) ="mouseEnter($event)"  (mouseleave) ="mouseLeave($event)"></a>
  
            <div [attr.data-route]=link.link class="d-flex d-lg-none nav-link align-items-end justify-content-between" [ngClass]="{'icon icon--arrow-down-white': link.submenu}" (click)="pickNavMobile($event)" [innerHTML]=link.text></div>
            <ul *ngIf="link.submenu" class="list-unstyled dropdown-menu" [ngClass]='{hide: subMenuOpen == false}'>
              <li *ngFor="let subnav of link.submenu">
                <a [routerLink]=subnav.link [innerHTML]=subnav.text class="d-none d-lg-block dropdown-item" (click)="pickSubNavDesktop($event)"></a>
                <div [attr.data-route]=subnav.link [innerHTML]=subnav.text class="d-lg-none dropdown-item" (click)="pickSubNavMobile($event)"></div>
              </li>
            </ul>
          </li>
      </ul>
    </div>

  </nav>

  <div *ngIf=!updateSubmitted class="collapse navbar-collapse d-lg-none" id="navbarUpdateDropdown" [ngClass]="{ 'show': updateOpen}">
    <app-updates-form id="mobile"></app-updates-form>
  </div>

  <div *ngIf="!updateSubmitted && showRegister" id="updateDesk" class="fixed-top cta cta--yellow d-none d-lg-block" [ngClass]="{ 'show shadow': updateOpen}">
    <div class="d-flex justify-content-between header">
      <div class="anchor d-flex align-items-center" (click)="!updateOpen && toggleUpdateDesktop()">
        <span class="icon icon--mail mr-2"></span>
        {{globalContents.updatedesk}}
      </div>
      <span class="close icon icon--close align-self-center" (click)="updateOpen && toggleUpdateDesktopClose()"></span>
    </div>
    <div class="form-container">
      <app-updates-form id="desktop"></app-updates-form>
    </div>
  </div>

</div>