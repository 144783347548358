<ul *ngIf=list class="mb-0 {{listclass}}">
  <li *ngFor="let item of list; let last = last" [ngClass]="{'mb-0': last}">
    <p [ngClass]="{'mb-0': last}">
      <span *ngIf="item.type != 'popover'" [innerHTML]=item.copy></span>
      <app-popover *ngIf="item.type == 'popover'" [data]=item></app-popover>
    </p>
    <ul *ngIf=item.sublist>
      <li *ngFor="let subitem of item.sublist; let first = first; let last = last" [ngClass]="{'mt-2 mt-lg-3': first}">
        <p>
          <span *ngIf="subitem.type != 'popover'" [innerHTML]=subitem.copy></span>
          <app-popover *ngIf="subitem.type == 'popover'" [data]=subitem></app-popover>
        </p>
      </li>
    </ul>
  </li>
</ul>