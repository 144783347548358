import { Component, OnInit } from '@angular/core';
import { ShareddataService } from "../../services/shareddata.service";
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'div[app-ribbon]',
  templateUrl: './ribbon.component.html',
  styleUrls: ['./ribbon.component.scss']
})
export class RibbonComponent implements OnInit {

  data: any;
  updateSubmitted: boolean;

  constructor(
    private shareddata: ShareddataService,
    private _cookieService: CookieService
  ) { }

  ngOnInit(): void {
    this.shareddata.ribbon.subscribe(state => this.data = state);
    this.shareddata.submit.subscribe(state => this.updateSubmitted = state);
    this.updateSubmitted = this._cookieService.get('formsubmitted') == 'true'; //verify if form was submitted
  }

  openForm(ev) {
    ev.stopPropagation();
    ev.preventDefault();
    !this.updateSubmitted && this.shareddata.openUpdateForm(true);
    return false;
  }
}
