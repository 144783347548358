import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as globaldata from "../../../../assets/data/all.json";
import { ShareddataService } from "../../services/shareddata.service";
import { ContactService } from '../../services/contact.service';

@Component({
  selector: 'app-updates-form',
  templateUrl: './updates-form.component.html',
  styleUrls: ['./updates-form.component.scss'],
  host: {
    '(document:click)': 'onDocumentClick($event)'
  },
  providers: [
    ContactService,
  ]
})
export class UpdatesFormComponent implements OnInit {

  @Input() id: string; //requires [id] to avoid conflicts on checkboxes(mobile vs desktop) - NavigationComponent instantiates this component twice
  formContents: any;
  form: FormGroup;
  isDropdownOpen: boolean;
  wasDropdownTouched: boolean;
  activeItem: string;
  activeItemId: string;
  formSubmitted: boolean;
  errors: number;
  navbarOpen: boolean;
  updateOpen: boolean;
  localFormSubmitted: boolean;
  token: any;

  constructor(
    private shareddata: ShareddataService,
    private contactservice: ContactService,
  ) { }

  ngOnInit(): void {
    this.formContents = globaldata[0].global.form;
    this.form = new FormGroup({
      firstname: new FormControl('', Validators.compose([
        Validators.required
      ])),
      lastname: new FormControl('', Validators.compose([
        Validators.required
      ])),
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.email,
        Validators.pattern("[^ @]*@[^ @]*")
      ])),
      specialty: new FormControl('', Validators.compose([
        Validators.required
      ])),
      zip: new FormControl('', Validators.compose([
        Validators.required
      ])),
      age: new FormControl('', Validators.compose([
        Validators.required
      ])),
      professional: new FormControl('', Validators.compose([
        Validators.required
      ]))
    });
    // this.form.hasError
    this.shareddata.submit.subscribe(state => this.formSubmitted = state);
    this.shareddata.formOpened.subscribe(state => this.updateOpen = state);
    this.activeItem = this.formContents.specialty;
    this.activeItemId = "";
    this.isDropdownOpen = false;
    this.wasDropdownTouched = false;
    this.localFormSubmitted = false;

    // gets token from ClearTrak
    // this.shareddata.token.subscribe(state => this.token = state);
    this.token = sessionStorage.getItem('tkn');
    if (this.token == null) {
      const that = this;

      this.contactservice.getToken()
        .subscribe(
          (data: any) => {
            sessionStorage.setItem('tkn', data.access_token);
            that.token = data.access_token;
            that.shareddata.updateCleartrakToken(that.token);
          },
          (error) => {
            console.log("Error", error.error.text);
          }
        );
    }
    else {
      this.shareddata.updateCleartrakToken(this.token);
    }
  }

  onSubmit() {

    if (this.form.valid) {

      const that = this;

      this.contactservice.subscribeUser(this.form.controls.firstname.value, this.form.controls.lastname.value, this.form.controls.email.value, this.form.controls.specialty.value, this.form.controls.zip.value)
      .subscribe(
        (data: any) => {
          // console.log('contactservice subscribeUser response', data);
          if (data.success === true) {
          
            this.localFormSubmitted = true;
            setTimeout(function() {
              that.shareddata.openUpdateForm(false); //dissapears form
              setTimeout(function() {
                that.shareddata.submitForm(true); //creates permanent cookie
              }, 1500);
            }, 1500);

          }
        },
        error => {
          console.log("Error", error.error.text);
        }
      );

    } else {
      this.validateAllFormFields(this.form);
    }
  };

  validateAllFormFields(formGroup: FormGroup) {
    this.errors = 0;
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        this.errors++;
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  toggleSpecialty() {
    this.isDropdownOpen = !this.isDropdownOpen;
    if (!this.wasDropdownTouched) {
      this.wasDropdownTouched = true;
    }
  }

  closeUpdate() {
    this.shareddata.openUpdateForm(false);
  }

  pickSpecialty(event) {
    this.isDropdownOpen = false;
    this.activeItem = event.target.dataset.name;
    this.activeItemId = event.target.dataset.value;
    this.form.controls.specialty.setValue(event.target.dataset.value);
  }

  onDocumentClick(event) {
    if (!(event.target.className.indexOf("copy") > -1 || event.target.className.indexOf("dropdown-toggle") > -1 || event.target.className.indexOf("arrow") > -1)) {
      this.isDropdownOpen = false;
    }
  }

}
