
<div  *ngIf="(data | json) != '{}' && data !== undefined" class="container-lg video-container">
  <div class="container px-lg-0">

    <div class="d-flex justify-content-center">

      <div class="wistia_embed wistia_async_{{data.id}} popover=true popoverContent=html popoverOverlayColor=000000 popoverOverlayOpacity=0.8 popoverSize=640x360 popoverPreventScroll=true playerColor=000000 endVideoBehavior=reset autoPlay=true silentAutoPlay=allow controlsVisibleOnLoad=true volume=0.5" style="display:inline;position:relative">
        <div class="play">
          <picture>
            <img src="/assets/img/{{data.illustration}}.{{data.ext}}" alt="{{data.alt}}" class="img-fluid" />
          </picture>
          <div *ngIf=data.label class="label mb-0 text-right d-flex flex-column justify-content-center" [innerHTML]=data.label></div>
        </div>
      </div>

    </div>

  </div>
</div>