import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { CookieService } from 'ngx-cookie-service';

@Injectable(
  {
    providedIn: 'root'
  }
)
export class ShareddataService {

  constructor(
    private _cookieService: CookieService
  ) { }

  private  referencesOpen = new BehaviorSubject<boolean>(false)
  refsBar = this.referencesOpen.asObservable();
  toggleReferences(state: boolean) {
    this.referencesOpen.next(state);
  }
  private referencesArray = new BehaviorSubject<any>([])
  references = this.referencesArray.asObservable();
  updateReferences(refs: {}) {
    this.referencesArray.next(refs);
  }

  private ribbonData = new BehaviorSubject<any>({})
  ribbon = this.ribbonData.asObservable();
  updateRibbon(data: {}) {
    this.ribbonData.next(data);
  }

  private  mobileNavOpen = new BehaviorSubject<boolean>(false)
  navBar = this.mobileNavOpen.asObservable();
  toggleNav(state: boolean) {
    this.mobileNavOpen.next(state);
  }

  //Form
  private openForm = new BehaviorSubject<boolean>(false)
  formOpened = this.openForm.asObservable(); //form overlay opened
  openUpdateForm(state: boolean) {
    this.openForm.next(state);
  }
  private  formSubmitted = new BehaviorSubject<boolean>(false)
  submit = this.formSubmitted.asObservable(); //submited form, creates permanent coockie
  submitForm(state: boolean) {
    this.formSubmitted.next(state);
    this._cookieService.set('formsubmitted', 'true' );
  }

  //Video
  private videoData = new BehaviorSubject<any>({})
  video = this.videoData.asObservable();
  updateVideo(data: {}) {
    this.videoData.next(data);
  }

  //Extrasection
  private extraSectionData = new BehaviorSubject<any>({})
  extraSection = this.extraSectionData.asObservable();
  updateExtraSection(data: any) {
    this.extraSectionData.next(data);
  }

  //Observable IQVIA token
  private cleartrakToken = new BehaviorSubject<any>({})
  token = this.cleartrakToken.asObservable();
  updateCleartrakToken(data: any) {
    this.cleartrakToken.next(data);
  }

}
