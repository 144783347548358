import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'div[app-footnotes]',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './footnotes.component.html',
  styleUrls: ['./footnotes.component.scss']
})
export class FootnotesComponent implements OnInit {

  @Input() footnotes: any;

  constructor() { }

  ngOnInit(): void {
  }

}
