<div *ngIf="data?.length > 0">
  <div class="container-fluid references-component pt-2" [ngClass]="{'open': refsBarOpen == true }">
    <div class="row">
      <div class="container-lg">
        <div class="row">
          <div class="col">
            <div><span class="hotspot d-inline-block pt-3 pb-3" (click)="toggleRefsBar()"><b>Reference<span *ngIf="data.length > 1">s</span> <span class="icon icon--arrow-down-red"></span></b></span></div>
            <ol class="list-inline m-0">
              <li *ngFor="let ref of data; index as i; let l = count"><strong> {{i + 1}}.&nbsp;</strong><span [innerHTML]=ref></span></li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>